import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import MuiTooltip, {
  TooltipProps as MuiTooltipProps,
} from "@material-ui/core/Tooltip";

/* eslint-disable-next-line */
export interface Props extends MuiTooltipProps {}

const useStyles = makeStyles({
  tooltip: {
    padding: "1rem",
    borderRadius: "0.75rem",
    fontSize: "0.875rem",
    color: "#808080",
    backgroundColor: "rgba(255, 255, 255, 0.9)",
    textAlign: "center",
  },
});

const Tooltip: React.FC<Props> = ({ ...others }) => {
  const classes = useStyles();
  return (
    <MuiTooltip classes={{ tooltip: classes.tooltip }} {...others}></MuiTooltip>
  );
};

export default Tooltip;
