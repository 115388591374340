import React from "react";

const ContactInfo = () => {
  return (
    <div className="flex flex-col">
      <div>Bản quyền thuộc về: Công ty TNHH Quảng Cáo Blue Agency Vietnam</div>
      <div>
        Địa chỉ: 51 Nguyễn Cư Trinh, Phường Nguyễn Cư Trinh, Quận 1, TP HCM
      </div>
    </div>
  );
};

export default ContactInfo;
