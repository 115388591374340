import React, { useEffect } from "react";
import classnames from "classnames";
import get from "lodash/get";
import { useRouter } from "next/router";
import { isMobile } from "react-device-detect";
import merge from "lodash/merge";
import Skeleton from "@material-ui/lab/Skeleton";
import Tooltip from "components/tooltip";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";

import ProfileCard from "components/profile-card";
import ProfileModal from "components/profile-modal";
import HomeSkeleton from "components/skeleton/home-skeleton";
import {
  DEFAULT_FILTER,
  FilterType,
  useActionBoard,
} from "components/action-board";
import ContactInfo from "components/contact-info";
import { useAppSelector, useAppDispatch } from "store";
import { refreshBottomMenu } from "features/app/appSlice";
import { IProfile } from "features/profile/type";
import { useProfileList } from "features/profile/hooks";
import useOnScreen from "hooks/useOnScreen";
import AlertModal from "../components/Dialog/AlertModal";

interface Props {
  isReady?: boolean;
  profileShow?: IProfile | null;
  defaultFilter?: FilterType;
}

const makePathToQuery = (path: string, options: FilterType) => {
  const searchParams = new URLSearchParams();
  const keys = Object.keys(options);
  keys.forEach((key: string) => {
    const value = get(options, [key], []).join(",");
    if (value && value !== "all") {
      searchParams.append(key, value);
    }
  });
  const searchStr = searchParams.toString();
  if (searchStr) {
    return `${path}?${searchStr}`;
  }
  return path;
};

const makeQueryToFilter = (query: {
  [key: string]: string | string[] | undefined;
}) => {
  const keys = Object.keys(query);
  const results: FilterType = { ...DEFAULT_FILTER };
  keys.forEach((key: string) => {
    if (
      ["platform", "province", "no_followers", "categories"].indexOf(key) >=
        0 &&
      query[key]
    ) {
      results[key as keyof FilterType] = (query[key] as string).split(",");
    }
  });
  return results;
};

const isDefault = (v: string[] | undefined) => {
  if (!v) return true;
  if (v.length === 1 && v[0] === "all") {
    return true;
  }
  return false;
};

const mergeFilter = (defaultFilter: FilterType, routerFilter: FilterType) => {
  const results: FilterType = { ...DEFAULT_FILTER };
  const keys = Object.keys(results);
  keys.forEach((key: string) => {
    if (
      !isDefault(routerFilter[key as keyof FilterType] as string[] | undefined)
    ) {
      results[key as keyof FilterType] = routerFilter[key as keyof FilterType];
      return;
    }
    results[key as keyof FilterType] = defaultFilter[key as keyof FilterType];
  });
  return results;
};

const Home: React.FC<Props> = ({
  isReady = true,
  profileShow = null,
  defaultFilter = { ...DEFAULT_FILTER },
}) => {
  const loadMoreRef = React.useRef<HTMLDivElement>(null);
  const restorationRef = React.useRef<HTMLDivElement>(null);
  const isOnScreen = useOnScreen(loadMoreRef);
  const popstate = useAppSelector((state) => state.app.popstate);
  const dispatch = useAppDispatch();
  const router = useRouter();
  const [rootRoute] = React.useState<string>(() => {
    if (router.pathname === "/[pid]") {
      return "/";
    }
    return router.asPath;
  });
  React.useEffect(() => {
    if (!isLoadingMore && isOnScreen) {
      setSize(size + 1);
    }
  }, [isOnScreen]);

  const [defaultFilterState] = React.useState(() => {
    return mergeFilter(defaultFilter, makeQueryToFilter(router.query));
  });

  const { filter, sort, handleFilterChange, setSort, HomeActionBoard } =
    useActionBoard({
      defaultFilter: defaultFilterState,
    });

  /* Search function on mobile */
  const searchFor = useAppSelector((state) => state.app.searchKey);
  const [extendFilter, setExtendFilter] = React.useState(
    merge({}, filter, { full_name: searchFor })
  );

  React.useEffect(() => {
    setExtendFilter(merge({}, filter, { full_name: searchFor }));
  }, [filter, searchFor]);
  /* End search function on mobile */
  const {
    profiles,
    isLoading,
    isLoadingMore,
    isEmpty,
    isReachingEnd,
    isRefreshing,
    error,
    size,
    setSize,
  } = useProfileList(isMobile ? extendFilter : filter, sort);

  const [showProfileModal, setShowProfileModal] = React.useState<boolean>(
    !!profileShow
  );

  useEffect(() => {
    if (profileShow?.isPrivate) {
      const { close } = AlertModal({
        title: "Thông báo",
        description: `<b>Trang cá nhân này đang tạm ẩn.</b><br/> Vui lòng quay lại sau!`,
        type: "error",
        bottomText: "Tôi đã hiểu",
        showCloseIcon: false,
        disableEscapeKeyDown: true,
        onBottomClick: () => {
          setShowProfileModal(false);
          close();
        },
      });
    }
  }, [profileShow?.isPrivate]);

  const [profile, setProfile] = React.useState<IProfile | null>(profileShow);

  const [markerId, setMarkerId] = React.useState(() => {
    if (!popstate) {
      return;
    }
    const persistedId = sessionStorage.getItem("scroll-to-profile-marker");
    sessionStorage.removeItem("scroll-to-profile-marker");
    return persistedId ? parseInt(persistedId) : null;
  });
  const onCardClick = React.useCallback(
    (profile: IProfile) => {
      if (isMobile) {
        if (profile?.influzeeID) {
          router.replace(makePathToQuery(router.asPath.split("?")[0], filter));
          router.push(`/profile-m/${profile.influzeeID}`);
          sessionStorage.setItem("scroll-to-profile-marker", `${profile?.id}`);
        }
        return;
      }
      setProfile(profile);
      setShowProfileModal(true);
    },
    [extendFilter]
  );

  useEffect(() => {
    if (!isMobile) {
      if (!showProfileModal) {
        history.replaceState({}, "", rootRoute);
      } else {
        history.replaceState({}, "", `/${profile?.influzeeID}`);
      }
    }
  }, [showProfileModal]);

  const [isShowBackToTop, setIsShowBackToTop] = React.useState<boolean>(false);

  const scrollToTop = React.useCallback(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const onScrollHandler = React.useCallback((y: number) => {
    if (y > 1000) {
      setIsShowBackToTop(true);
    } else {
      setIsShowBackToTop(false);
    }
  }, []);

  useEffect(() => {
    let lastKnownPosition = 0;
    let tick = false;
    const onScroll = () => {
      lastKnownPosition = window.scrollY;
      if (!tick) {
        window.requestAnimationFrame(function () {
          onScrollHandler(lastKnownPosition);
          tick = false;
        });
      }
      tick = true;
    };
    window.addEventListener("scroll", onScroll);
    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, []);

  useEffect(() => {
    if (isReady) {
      dispatch(refreshBottomMenu());
    }
  }, [size, isReady]);

  if (!isReady) return null;
  return (
    <>
      <div className="sm:mt-56 w-full">
        <div className="block sm:fixed sm:w-screen sm:top-20 sm:left-0 sm:pl-67.5 sm:pr-7.5 sm:bg-white sm:z-40 sm:pb-7.5">
          <HomeActionBoard
            filter={filter}
            sort={sort}
            handleFilterChange={handleFilterChange}
            setSort={setSort}
          />
        </div>
        <div
          className={classnames(
            "fixed bottom-24 right-0 pr-5 transform translate-x-full custom-transition z-80",
            {
              show: isShowBackToTop,
            }
          )}
        >
          <div
            className="w-12 h-12 bg-pale-grey rounded-full flex justify-center items-center group cursor-pointer"
            onClick={scrollToTop}
          >
            <KeyboardArrowUpIcon
              style={{ fontSize: "2.5rem" }}
              className="transform group-hover:-translate-y-1 custom-transition text-greyish-brown"
            />
          </div>
        </div>
        {!isMobile && (
          <>
            <Tooltip title={<ContactInfo />} placement="left-end">
              <div
                className={classnames(
                  "fixed bottom-10 right-0 pr-5 transform translate-x-full custom-transition z-80 show"
                )}
              >
                <div
                  className="w-12 h-12 rounded-full flex justify-center items-center group cursor-pointer"
                  onClick={scrollToTop}
                >
                  <HelpOutlineIcon
                    style={{ fontSize: "3rem" }}
                    className="transform group-hover:-translate-y-1 custom-transition text-greyish-brown"
                  />
                </div>
              </div>
            </Tooltip>
          </>
        )}
        {error ? (
          <div className="flex flex-col justify-center w-100 px-5 pt-5 sm:px-80 sm:pt-8">
            <img src="/assets/sidemenu-logo/error.svg" className="w-100" />
            <h3 className="text-center text-lg sm:text-xl font-bold mb-2 mt-8">
              Oops! Chúng tôi đang cố gắng thử lại!
            </h3>
          </div>
        ) : (
          <div className="mt-3 grid grid-cols-2 px-5 sm:px-0 sm:grid-cols-3 home-web-md:grid-cols-4 home-web-xl:grid-cols-5 home-web-2xl:grid-cols-6 gap-4 sm:gap-7 home-container">
            {isLoading ? (
              <HomeSkeleton />
            ) : profiles?.length === 0 ? (
              <div className="flex flex-col col-span-2 sm:col-span-1 sm:shadow-lg sm:rounded-2xl relative overflow-hidden">
                <div className="sm:p-4">
                  <div className="w-full relative">
                    <Skeleton
                      variant="rect"
                      width={"100%"}
                      height={"auto"}
                      style={{ padding: "100% 0 0 0" }}
                      animation={false}
                    />
                    <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-lg text-center w-full px-8">
                      Rất tiếc! <br />
                      <span className="text-sm">
                        Chúng tôi chưa tìm thấy KOL nào thỏa yêu cầu của bạn
                      </span>
                    </div>
                  </div>
                  <div className="w-full mt-3 flex flex-col">
                    <h3 className="text-lg font-bold line-clamp-1">
                      <Skeleton variant="text" animation={false} />
                    </h3>
                    <h4 className="text-xs text-grey-80 line-clamp-1">
                      <Skeleton variant="text" animation={false} />
                    </h4>
                  </div>
                  <div className="w-full mt-3">
                    <Skeleton
                      variant="rect"
                      width={"100%"}
                      height={"2rem"}
                      animation={false}
                    />
                  </div>
                </div>
              </div>
            ) : (
              profiles?.map((i: IProfile) => (
                <ProfileCard
                  key={i.id}
                  platform={filter.platform}
                  onClick={onCardClick.bind(null, i)}
                  restorationRef={markerId === i.id ? restorationRef : null}
                  restorationCallback={() => setMarkerId(null)}
                  {...i}
                />
              ))
            )}
            {!isReachingEnd && <HomeSkeleton needItem={12} ref={loadMoreRef} />}
          </div>
        )}
        <ProfileModal
          profile={profile}
          isShow={showProfileModal}
          handleClose={() => setShowProfileModal(false)}
        />
      </div>
    </>
  );
};

export default Home;
