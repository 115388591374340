import React from "react";
import Home from "containers/Home";
import { GetServerSideProps } from "next";
import { wrapper } from "store";
import { updateDefault } from "features/seo/seoSlice";

export const getServerSideProps: GetServerSideProps = wrapper.getServerSideProps(
  (store) => async () => {
    store.dispatch(updateDefault({ hideCanonical: true }));
    return {
      props: {},
    };
  }
);

interface Props {
  isReady?: boolean;
}
const Index: React.FC<Props> = ({ isReady = true }) => {
  return (
    <>
      <Home isReady={isReady} />
    </>
  );
};

export default Index;
